import { render, staticRenderFns } from "./Tutorial.vue?vue&type=template&id=28055723&scoped=true"
import script from "./Tutorial.vue?vue&type=script&lang=js"
export * from "./Tutorial.vue?vue&type=script&lang=js"
import style0 from "./Tutorial.vue?vue&type=style&index=0&id=28055723&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28055723",
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep,QStepperNavigation,QBtn});
